<template>
  <div>
    <el-card v-loading="isLoading">
      <el-page-header @back="goBack" :content="ticketLabel">
      </el-page-header>
      <div v-if="isOpenDocument">
        <el-divider></el-divider>
        <el-button native-type="submit" type="basic" @click="signDocument()" v-if="!isDocumentSigned  && currentBase64 && canSignTickets">
          Подписать
          <i class="el-icon-check" style="font-weight: bold"/>
        </el-button>
        <el-button native-type="submit" type="basic" @click="downloadSign()" v-if="isDocumentSigned">
          Скачать подпись
          <i class="el-icon-check" style="font-weight: bold"/>
        </el-button>
        <el-button v-if="!isRejected && canCancelTickets" native-type="submit" type="warning" @click="rejectDialog = true">
          Отклонить заявку
          <i class="el-icon-close" style="font-weight: bold"/>
        </el-button>
        <el-button native-type="submit" type="basic" @click="editDocument()" v-if="!isEditing && !currentCms && canEditTickets">
          Редактировать
          <i class="el-icon-edit" style="font-weight: bold"/>
        </el-button>
        <el-button style="margin-bottom: 20px;" native-type="submit" type="basic" @click="copyDocument()" v-if="!isEditing && !isCopying && isRejected && canCreateTickets">
          Создать новую заявку
          <i class="el-icon-edit" style="font-weight: bold"/>
        </el-button>
        <el-divider></el-divider>
      </div>
      <div v-if="isRejected" class="reject">
        Заявка отклонена: {{ rejectingReason }}
      </div>
      <div v-else style="margin-top: 20px;">
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="is-flex is-justify-center">
        <el-tab-pane label="Документ" name="1">
          <el-form :rules="rules" style="margin-top: 10px;" label-position="top" @submit.native.prevent label-width="80px" :model="docForm"  ref="ruleForm" >
            <el-row>
              <el-col v-for="attr in documentAttributes" :key="attr.key" :span="12">
                <el-form-item v-if="attr.type === 'input'" :label="attr.label" :prop="attr.key">
                  <el-input v-model="docForm[attr.key]" :readonly="isOpenDocument || (attr.disabled && isEditing)" ></el-input>
                </el-form-item>
                <el-form-item v-if="attr.type === 'date'" :label="attr.label" :prop="attr.key">
                  <el-date-picker
                      v-model="docForm[attr.key]"
                      :readonly="isOpenDocument || (attr.disabled && isEditing)"
                      class="el-button--full-width"
                      type="datetime"
                      placeholder="Выберите дату">
                  </el-date-picker>
                </el-form-item>
                <el-form-item v-if="attr.type === 'select'" :label="attr.label" :prop="attr.key">
                  <el-select v-model="docForm[attr.key]" :disabled="isOpenDocument || (attr.disabled && isEditing)" class="m-2 el-button--full-width" placeholder="Компания" size="large">
                    <el-option
                        v-for="item in attr.data"
                        :key="item[attr.selectKey]"
                        :label="item[attr.selectLabel]"
                        :value="item[attr.selectValue]"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Таблица" name="2">
          <el-autocomplete
              v-if="!isOpenDocument"
              class="inline-input"
              v-model="carNumberSearch"
              :fetch-suggestions="querySearch"
              :value-key="'carNumber'"
              placeholder="Добавить вагон"
              @select="carSelect"
              :maxlength="8"
              style="margin-bottom: 20px;"
          ></el-autocomplete>
          <el-tooltip v-if="canAddNewRailcar && carNumberSearch.length === 8" content="Добавить вагон">
            <el-button @click="addNewRailcar"
                       style="margin-left: 10px;">
              <i class="el-icon-plus" style="font-weight: bold"/>
            </el-button>
          </el-tooltip>
          <el-tooltip v-if="!isOpenDocument" content="Добавить вагоны списком">
            <el-button @click="addRailcarsDialog = true"
                       style="margin-left: 10px;">
              <i class="el-icon-tickets" style="font-weight: bold"/>
            </el-button>
          </el-tooltip>
          <el-tooltip v-if="!isOpenDocument && selectRailcars.length > 0" content="Изменение значения вагонов">
            <el-button @click="changeWagonsValueDialog = true"
                       style="margin-left: 10px;">
              <i class="el-icon-edit" style="font-weight: bold"/>
            </el-button>
          </el-tooltip>
          <el-tooltip v-if="!isOpenDocument && selectRailcars.length > 0" content="Удалить выбраннные вагоны">
            <el-button @click="deleteRow"
                       style="margin-left: 10px;">
              <i class="el-icon-delete" style="font-weight: bold"/>
            </el-button>
          </el-tooltip>
          <el-table
              ref="workplaceTable"
              :data="tableData"
              @selection-change="handleSelectionChange"
              @select="handleSelect"
              size="small"
              style="width: 100%">
            <el-table-column
                v-if="!isOpenDocument"
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="position"
                label="№"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.position }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="nomerVagona"
                label="Номер вагона"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.nomerVagona }}</span>
              </template>
            </el-table-column>
            <el-table-column
                v-for="attr in workplaceAttributes"
                :key="attr.key"
                :prop="attr.key"
                :label="attr.label"
            >
              <template slot-scope="scope">
                <el-select v-if="attr.type === 'select'" v-model="scope.row[attr.key]" :disabled="isOpenDocument" class="m-2 el-button--full-width" size="large">
                  <el-option
                      v-for="item in attr.data"
                      :key="item[attr.selectKey]"
                      :label="item[attr.selectLabel]"
                      :value="item[attr.selectValue]"
                  />
                </el-select>
                <el-date-picker
                    v-if="attr.type === 'date'"
                    v-model="scope.row[attr.key]"
                    :readonly="isOpenDocument"
                    class="el-button--full-width"
                    type="datetime"
                    :placeholder="attr.label">
                </el-date-picker>
                <el-input @input="changeWorkplaceValue(scope.row[attr.key], scope.row, attr.key)" v-if="attr.type === 'input'" v-model="scope.row[attr.key]" :readonly="isOpenDocument"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane v-if="isOpenDocument" label="История" name="3">
          <div class="history-table">
            <el-table
                :data="documentHistory"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                style="width: 100%; cursor: pointer">
              <el-table-column
                  prop="meta.documentId"
                  label="Id документа">
              </el-table-column>
              <el-table-column
                  label="Номер документа">
                <template slot-scope="scope">
                  {{ scope.row.data.body ? scope.row.data.body.documentWorkplaceRailcar.documentRailcar.docNumber : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="meta.sendedAt"
                  label="Дата отправления">
              </el-table-column>
              <el-table-column
                  prop="data.body.documentWorkplaceRailcar.documentRailcar.createdDate"
                  label="Дата cоздания">
                <template slot-scope="scope">
                  {{ scope.row.data.body ? getFormattedDate(scope.row.data.body.documentWorkplaceRailcar.documentRailcar.createdDate) : '' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="isOpenDocument" label="Подписи" name="4">
          <el-card v-if="signData.length > 0" style="margin-top: 30px;">
            <h3>Информация об электронном документе</h3>
            <div class="sign-table">
              <table v-for="item in signData" :key="item.certificate" style="margin-bottom: 30px;">
                <tr>
                  <td>Результат проверки сертификата</td>
                  <td>{{ item.result }}</td>
                </tr>
                <tr>
                  <td>ИИН</td>
                  <td>{{ item.iin }}</td>
                </tr>
                <tr>
                  <td>Издатель</td>
                  <td>{{ item.issuer_name }}</td>
                </tr>
                <tr>
                  <td>ФИО</td>
                  <td>{{ item.name + ' ' + item.father_name }}</td>
                </tr>
                <tr>
                  <td>Серийный номер сертификата</td>
                  <td>{{ item.certificate }}</td>
                </tr>
                <tr>
                  <td>Срок действия сертификата</td>
                  <td>{{ item.time_from + ' - ' + item.until }}</td>
                </tr>
                <tr v-if="item.organization">
                  <td>Организация</td>
                  <td>{{ item.organization }}</td>
                </tr>
                <tr v-if="item.organization_bin">
                  <td>БИН организаций</td>
                  <td>{{ item.organization_bin }}</td>
                </tr>
              </table>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane v-if="isOpenDocument" label="Печатная форма" name="5">
          <el-card>
            <transition name="fade">
              <iframe
                  :src="base64PdfPath"
                  width='100%' height="500"
              />
            </transition>
          </el-card>
        </el-tab-pane>
        <el-tab-pane v-if="isOpenDocument" label="Статусы" name="6">
          <div class="history-table">
            <el-table
                :data="statuses"
                :row-class-name="tableRowClassName"
                style="width: 100%; cursor: pointer">
              <el-table-column
                  prop="statusName"
                  label="Статус">
              </el-table-column>
              <el-table-column
                  prop="createdAt"
                  label="Дата">
                <template slot-scope="scope">
                  {{ scope.row.createdAt ? getFormattedDate(scope.row.createdAt) : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="comment"
                  label="Комментарий">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="margin-top: 20px; display: flex; justify-content: space-between; align-items: center">
        <div>
          <el-button v-if="activeName !== '1'" :disabled="isLoading" native-type="submit" @click="stepHandle('back')">
            Назад
            <i class="el-icon-arrow-left" style="font-weight: bold"/>
          </el-button>
          <el-button v-if="tabsAmount.toString() !== activeName" :disabled="isLoading" native-type="submit" @click="stepHandle('next')">
            Далее
            <i class="el-icon-arrow-right" style="font-weight: bold"/>
          </el-button>
          <el-button v-if="!isOpenDocument" :disabled="isLoading" type="primary" native-type="submit" @click="createDocument">
            Сохранить
            <i class="el-icon-check" style="font-weight: bold"/>
          </el-button>
          <el-button v-if="isEditing" @click="goBack()">
            Отменить
            <i class="el-icon-close" style="font-weight: bold"/>
          </el-button>
        </div>
        <div>
          <p style="font-size: 13px">Кол-во вагонов: {{ tableData.length }}</p>
        </div>
      </div>
    </el-card>
    <el-dialog title="Отклонение заявки" :visible.sync="rejectDialog">
      <el-input
          placeholder="Причина отклонения"
          v-model="rejectValue"
      ></el-input>
      <div style="margin-top: 20px;">
        <el-button @click="rejectDialog = false">Отмена</el-button>
        <el-button @click="rejectTicket(rejectValue)">Добавить</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="currentHistoryItem.hash" :visible.sync="historyDialog">
      <pre>{{ currentHistoryItem.meta }}</pre>
      <br>
      <pre>{{ currentHistoryItem.data }}</pre>
    </el-dialog>
    <el-dialog title="Добавление вагонов списком" :visible.sync="addRailcarsDialog">
      <div style="display: flex; justify-content: space-between;">
        <el-input
            type="textarea"
            :rows="10"
            :loading="isLoading"
            placeholder="Вставьте список"
            v-model="railcarsList"
            @input="findFromString(railcarsList)"
        ></el-input>
        <div v-if="railcars.length > 0" style="width: 100%; margin-left: 30px;">
          <table class="railcarTable">
            <tr v-for="railcar in railcars" :key="railcar.position">
              <td>
                {{ railcar.position + 1 }}
              </td>
              <td>
                {{ railcar.carNumber }}
              </td>
              <td>
                <span v-if="railcar.error" style="color: red">{{ railcar.error }}</span>
                <span v-else style="color: green">Найден</span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div style="margin-top: 20px;">
        <el-button @click="cancelAddRailcars">Отмена</el-button>
        <el-button v-if="railcars.length > 0" @click="addRailcarsFromList()">Добавить</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Изменение значения вагонов" :visible.sync="changeWagonsValueDialog">
      <div v-for="attr in workplaceAttributes" :key="attr.key">
        <el-select v-if="attr.type === 'select'"
                   :placeholder="attr.label"
                   style="margin-bottom: 10px;"
                   v-model="wagonsValues[attr.key]"
                   :disabled="isOpenDocument"
                   class="m-2 el-button--full-width"
                   size="large">
          <el-option
              v-for="item in attr.data"
              :key="item[attr.selectKey]"
              :label="item[attr.selectLabel]"
              :value="item[attr.selectValue]"
          />
        </el-select>
        <el-date-picker
            v-if="attr.type === 'date'"
            v-model="wagonsValues[attr.key]"
            :readonly="isOpenDocument"
            class="el-button--full-width"
            type="datetime"
            style="margin-bottom: 10px;"
            :placeholder="attr.label">
        </el-date-picker>
        <el-input v-if="attr.type === 'input'" :placeholder="attr.label" v-model="wagonsValues[attr.key]" :readonly="isOpenDocument"></el-input>
      </div>
      <div style="margin-top: 20px;">
        <el-button @click="cancelChangeRailcars">Отмена</el-button>
        <el-button @click="changeRailcarsValue">Применить</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="signDialogVisible"
        width="30%">
      <span>Введите пароль подписи</span>
      <el-input v-model="ecpPassword"></el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="signDialogVisible = false">Отмена</el-button>
    <el-button type="primary" @click="signNcaNode()">Подтвердить</el-button>
  </span>
    </el-dialog>
  </div>

</template>

<script>
import companiesApi from '@/modules/api/companies'
import blockchainApi from '@/modules/api/blockchain'
import railcarApi from '@/modules/api/railcar'
import evenPodacha from '@/modules/directories/evenPodacha'
import evenPodachaErtys from '@/modules/directories/evenPodachaErtys'
import evenPodachaKaz from '@/modules/directories/evenPodachaKaz'
import outGoodPodach from '@/modules/directories/outGoodPodach'
import outGoodPodachErtys from '@/modules/directories/outGoodPodachErtys'
import outGoodPodachKaz from '@/modules/directories/outGoodPodachKaz'
import dateFormat from '@/modules/directories/date/date'
import { MAIN_API } from '@/modules/api/apiUrl'
import signApi from '@/modules/api/sign'
import { parse } from 'date-fns'
import { NCALayerClient } from 'ncalayer-js-client'
import authorizationAPI from '@/modules/api/authorization'
import executorsKaz from '@/modules/directories/executorsKaz'
import executorsErtys from '@/modules/directories/executorsErtys'
import directions from '@/modules/directories/directions'
import { canEditTickets, canCancelTickets, canSignTickets, canCreateTickets } from '@/modules/permissions/permissions'

export default {
  name: 'CreateTicket',
  data () {
    return {
      isLoading: false,
      isMobile: false,
      signDialogVisible: false,
      ecpPassword: '',
      documentInfo: {},
      companies: [],
      tableData: [],
      selectRailcars: [],
      lastSelectedIndex: null,
      isShiftPressed: false,
      wagonsValues: {},
      changeWagonsValueDialog: false,
      docForm: {},
      activeName: '1',
      rules: {
        docNumber: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        executor: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      carNumberSearch: '',
      documentId: '',
      currentMeta: {},
      docType: '',
      isDocumentSigned: false,
      documentHistory: [],
      statuses: [],
      historyDialog: false,
      rejectDialog: false,
      rejectValue: '',
      addRailcarsDialog: false,
      currentHistoryItem: {},
      currentBase64: '',
      currentBase64Pdf: '',
      currentCms: '',
      currentBase64Item: {},
      signData: [],
      railcarsList: '',
      railcars: [],
      documentAttributes: [],
      workplaceAttributes: [],
      attributeRequestsName: '',
      attributeRequestName: '',
      ticketLabel: '',
      canAddNewRailcar: false
    }
  },
  async mounted () {
    this.initComponents()
    this.getUsersKey()
    this.isMobile = window.innerWidth <= 768
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  },
  computed: {
    executors () {
      if (this.isUserErtys) {
        return executorsErtys.data
      }
      return executorsKaz.data
    },
    isUserKazykurt () {
      return authorizationAPI.isRoleKazykurt()
    },
    isUserErtys () {
      return authorizationAPI.isRoleErtys()
    },
    isUserBatys () {
      return authorizationAPI.isRoleBatys()
    },
    isRejected () {
      if (this.isOpenDocument) {
        if (!this.documentInfo.data) {
          return false
        }
        return !!this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.rejectingReason
      }
      return false
    },
    rejectingReason () {
      return this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.rejectingReason
    },
    evenPodacha () {
      if (this.isUserErtys) {
        return evenPodachaErtys.data
      } else if (this.isUserKazykurt) {
        return evenPodachaKaz.data
      }
      return evenPodacha.data
    },
    outGoodPodach () {
      if (this.isUserErtys) {
        return outGoodPodachErtys.data
      } else if (this.isUserKazykurt) {
        return outGoodPodachKaz.data
      }
      return outGoodPodach.data
    },
    processingTypes () {
      return [{ title: '' }, { title: 'Наружная и внутренняя' }]
    },
    processingTypesDrkr () {
      return [
        { title: '' },
        { title: 'Внутренняя и наружная мойка' },
        { title: 'Наружная мойка' },
        { title: 'Внутренняя мойка' }
      ]
    },
    processingTypesExternal () {
      return [
        { title: '' },
        { title: 'Наружная мойка' }
      ]
    },
    getDirections () {
      return directions.data
    },
    atStations () {
      return [{ title: 'Да' }, { title: 'Нет' }]
    },
    isOpenDocument () {
      return !!(this.$route.query.hash && !this.$route.query.type)
    },
    canEditTickets () {
      return canEditTickets(authorizationAPI.getRole())
    },
    canCancelTickets () {
      return canCancelTickets(authorizationAPI.getRole())
    },
    canCreateTickets () {
      return canCreateTickets(authorizationAPI.getRole())
    },
    canSignTickets () {
      return canSignTickets(authorizationAPI.getRole())
    },
    isEditing () {
      return this.$route.query.type === 'edit'
    },
    isCopying () {
      return this.$route.query.type === 'copy'
    },
    isNewDoc () {
      return !this.isEditing && !this.isOpenDocument
    },
    ticketName () {
      return this.$route.params.name
    },
    getHash () {
      return this.$route.query.hash
    },
    getUsername () {
      return JSON.parse(sessionStorage.getItem('loginUserInfo')).username
    },
    getUserCompanyBin () {
      return JSON.parse(sessionStorage.getItem('loginUserInfo')).company.bin
    },
    base64Path () {
      return 'data:application/pdf;base64,' + this.currentBase64
    },
    base64PdfPath () {
      return 'data:application/pdf;base64,' + this.currentBase64Pdf
    },
    tabsAmount () {
      if (this.isOpenDocument) {
        return 5
      }
      return 2
    }
  },
  watch: {
    $route (to, from) {
      this.initComponents() // Вызов getData при изменении маршрута
    }
  },
  methods: {
    changeWorkplaceValue (value, row, key) {
      if (this.isUserBatys) {
        if (this.ticketName === 'processing') {
          if (key === 'primechaniyePodacha') {
            this.tableData.forEach(item => {
              if (item.outGoodPodach === row.outGoodPodach) {
                item.primechaniyePodacha = value
              }
            })
          }
        } else if (this.ticketName === 'inspection') {
          if (key === 'primechaniyeInspection') {
            this.tableData.forEach(item => {
              if (item.inGoodInspection === row.inGoodInspection) {
                item.primechaniyeInspection = value
              }
            })
          }
        }
      }
    },
    onKeyDown (event) {
      if (event.key === 'Shift') {
        this.isShiftPressed = true
      }
    },
    onKeyUp (event) {
      if (event.key === 'Shift') {
        this.isShiftPressed = false
      }
    },
    handleSelect (selection, row) {
      const rowIndex = this.tableData.findIndex(item => item === row)
      if (this.isShiftPressed && this.lastSelectedIndex === null) {
        this.lastSelectedIndex = rowIndex
        return
      }
      if (this.isShiftPressed && this.lastSelectedIndex !== null) {
        const start = Math.min(this.lastSelectedIndex, rowIndex)
        const end = Math.max(this.lastSelectedIndex, rowIndex)
        for (let i = start; i <= end; i++) {
          this.$refs.workplaceTable.toggleRowSelection(this.tableData[i], true)
        }
        this.lastSelectedIndex = null
      }
    },
    initComponents () {
      this.docFormChanges()
      this.getCompanies()
      if (this.isOpenDocument || this.isEditing || this.isCopying) {
        this.getDocumentByHash()
      }
      this.getFilteredHistory()
    },
    docFormChanges () {
      let companies = this.companies
      if (this.isUserBatys) {
        companies = this.companies.filter(item => item.id === 3)
      }
      switch (this.ticketName) {
        case 'processing':
          this.ticketLabel = 'Заявка на обработку'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель 2', key: 'receiverCompany2', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Подьезные пути', key: 'pathList', type: 'input', disabled: false },
            { label: 'Номер состава', key: 'trainNumberPodacha', type: 'input', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false },
            { label: 'Исполнитель', key: 'executor', type: 'select', data: this.executors, selectKey: 'title', selectLabel: 'title', selectValue: 'title', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Действие', key: 'eventPodacha', type: 'select', data: this.evenPodacha, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
            { label: 'Ранее перевозимый груз', key: 'outGoodPodach', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
            { label: 'Под погрузку', key: 'inGoodPodacha', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
            { label: 'Примечание', key: 'commentForRailcarProc', type: 'input' }
          ]
          if (!this.isUserKazykurt && !this.isUserErtys) {
            this.documentAttributes = this.documentAttributes.filter(item => item.key !== 'executor')
          }
          this.docForm = {
            docNumber: '',
            trainNumberPodacha: '',
            pathList: '',
            senderCompany: 1,
            receiverCompany: 8,
            receiverCompany2: null,
            createdDate: new Date(),
            executor: ''
          }
          this.wagonsValues = {
            eventPodacha: '',
            outGoodPodach: '',
            inGoodPodacha: '',
            commentForRailcarProc: '',
            executor: ''
          }
          if (this.isUserBatys) {
            this.workplaceAttributes = [
              { label: 'Действие', key: 'eventPodacha', type: 'select', data: this.evenPodacha, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
              { label: 'Ранее перевозимый груз', key: 'outGoodPodach', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
              { label: 'Под погрузку', key: 'inGoodPodacha', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
              { label: 'Примечание', key: 'primechaniyePodacha', type: 'input' }
            ]
            this.wagonsValues = {
              eventPodacha: '',
              outGoodPodach: '',
              inGoodPodacha: '',
              primechaniyePodacha: '',
              executor: ''
            }
            this.docForm.senderCompany = 3
          }
          this.attributeRequestsName = 'attributeRequestsProcessing'
          this.attributeRequestName = 'attributeRequestProcessing'
          break
        case 'vrd':
          this.ticketLabel = 'Заявка на ВРД'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Исполнитель', key: 'executor', type: 'select', data: this.executors, selectKey: 'title', selectLabel: 'title', selectValue: 'title', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Вид обработки', key: 'processingTypeUborka', type: 'select', data: this.processingTypes, selectKey: 'title', selectLabel: 'title', selectValue: 'title' }
          ]
          this.docForm = {
            docNumber: '',
            executor: '',
            senderCompany: 1,
            receiverCompany: 8,
            createdDate: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
          }
          this.wagonsValues = {
            processingTypeUborka: ''
          }
          this.attributeRequestsName = 'attributeRequestsVrd'
          this.attributeRequestName = 'attributeRequestVrd'
          break
        case 'raisingApplication':
          this.ticketLabel = 'Заявка на поднятие состава'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Дата и время прибытия на Тендык', key: 'dateTimeTendykRaisingApplication', type: 'date', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Станция бросания', key: 'castingStationRaisingApplication', type: 'input' },
            { label: 'Индекс', key: 'indexRaisingApplication', type: 'input' },
            { label: 'Время подачи на ст. Промышленная', key: 'timeIndustrialRaisingApplication', type: 'date', disabled: false }
          ]
          this.docForm = {
            docNumber: '',
            senderCompany: 1,
            receiverCompany: 8,
            dateTimeTendykRaisingApplication: null,
            createdDate: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
          }
          this.wagonsValues = {
            castingStationRaisingApplication: '',
            indexRaisingApplication: '',
            timeIndustrialRaisingApplication: ''
          }
          this.attributeRequestsName = 'attributeRequestsRaisingApplication'
          this.attributeRequestName = 'attributeRequestRaisingApplication'
          break
        case 'drKr':
          this.ticketLabel = 'Заявка на ДрКР'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Исполнитель', key: 'executor', type: 'select', data: this.executors, selectKey: 'title', selectLabel: 'title', selectValue: 'title', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Вид обработки', key: 'processingType2', type: 'select', data: this.processingTypesDrkr, selectKey: 'title', selectLabel: 'title', selectValue: 'title' }
          ]
          this.docForm = {
            docNumber: '',
            executor: '',
            senderCompany: 1,
            receiverCompany: 7,
            createdDate: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
          }
          this.wagonsValues = {
            castingStationRaisingApplication: '',
            indexRaisingApplication: '',
            timeIndustrialRaisingApplication: ''
          }
          this.attributeRequestsName = 'attributeRequestsDrKr'
          this.attributeRequestName = 'attributeRequestDrKr'
          break
        case 'inspection':
          this.ticketLabel = 'Заявка на Осмотр'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'На другой станции', key: 'atAnotherStationInspection', type: 'select', data: this.atStations, selectKey: 'title', selectLabel: 'title', selectValue: 'title', disabled: false },
            { label: 'Номер состава', key: 'trackNumberInspection', type: 'input', disabled: false },
            { label: 'Номер пути', key: 'pathInspection', type: 'input', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Ранее перевозимый груз', key: 'wagonsData', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
            { label: 'Под налив', key: 'inGoodInspection', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
            { label: 'Тип вагона', key: 'wagonTypeInspection', type: 'input' }
          ]
          this.docForm = {
            docNumber: '',
            atAnotherStationInspection: '',
            trackNumberInspection: '',
            pathInspection: '',
            senderCompanyInspection: 1,
            receiverCompanyInspection: 8,
            createdDate: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
            this.workplaceAttributes = [
              { label: 'Ранее перевозимый груз', key: 'wagonsData', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
              { label: 'Под налив', key: 'inGoodInspection', type: 'select', data: this.outGoodPodach, selectKey: 'id', selectLabel: 'title', selectValue: 'title' },
              { label: 'Тип вагона', key: 'wagonTypeInspection', type: 'input' },
              { label: 'Примечание', key: 'primechaniyeInspection', type: 'input' }
            ]
          }
          this.wagonsValues = {
            wagonTypeInspection: '',
            inGoodInspection: '',
            wagonsData: ''
          }
          this.attributeRequestsName = 'attributeRequestsInspection'
          this.attributeRequestName = 'attributeRequestInspection'
          break
        case 'sendApplication':
          this.ticketLabel = 'Заявка на отправку'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Директор представительства', key: 'representativeDirectorSendApplication', type: 'input', disabled: false },
            { label: 'Переподача на путь', key: 'pathSendApplication', type: 'input', disabled: false },
            { label: 'Отправка', key: 'sendingSendApplication', type: 'input', disabled: false },
            { label: 'Исполнитель', key: 'executorSendApplication', type: 'input', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Станция назначения', key: 'destinationStationSendApplication', type: 'input' }
          ]
          this.docForm = {
            docNumber: '',
            recipientSendApplication: '',
            representativeDirectorSendApplication: '',
            sendingCompanySendApplication: '',
            pathSendApplication: '',
            sendingSendApplication: '',
            executorSendApplication: '',
            senderCompany: 1,
            receiverCompany: 8,
            createdDate: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
          }
          this.wagonsValues = {
            destinationStationSendApplication: ''
          }
          this.attributeRequestsName = 'attributeRequestsSendApplication'
          this.attributeRequestName = 'attributeRequestSendApplication'
          break
        case 'tor':
          this.ticketLabel = 'Заявка на ТОР'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Направление', key: 'direction', type: 'select', data: this.getDirections, selectKey: 'id', selectLabel: 'title', selectValue: 'title', disabled: true },
            { label: 'Подьезные пути', key: 'pathList', type: 'input', disabled: false },
            { label: 'Вид ремонта', key: 'repairType', type: 'input', disabled: false },
            { label: 'Исполнитель', key: 'executorSendApplication', type: 'input', disabled: false },
            { label: 'Отправление с пути', key: 'pathDeparture', type: 'input', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Неисправность', key: 'neispravnost', type: 'input' }
          ]
          this.docForm = {
            docNumber: '',
            direction: '',
            pathList: '',
            repairType: '',
            pathDeparture: '',
            executorSendApplication: '',
            senderCompany: 1,
            receiverCompany: 8,
            createdDate: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
          }
          this.wagonsValues = {
            neispravnost: ''
          }
          this.attributeRequestsName = 'attributeRequestsTor'
          this.attributeRequestName = 'attributeRequestTor'
          break
        case 'uncreditedWagons':
          this.ticketLabel = 'Уведомление на нераскредитованных вагонов'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Индекс состава', key: 'compositionIndexUncredited', type: 'input', disabled: false },
            { label: 'Дата и время прибытия на ст. Промышленная', key: 'dateTimeIndustrialUncredited', type: 'date', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
          ]
          this.docForm = {
            docNumber: '',
            senderCompany: 1,
            receiverCompany: 8,
            createdDate: new Date(),
            compositionIndexUncredited: '',
            dateTimeIndustrialUncredited: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
          }
          this.wagonsValues = {
          }
          this.attributeRequestsName = 'attributeRequestsUncreditedWagons'
          this.attributeRequestName = 'attributeRequestUncreditedWagons'
          break
        case 'externalCleaning':
          this.ticketLabel = 'Заявка на наружную мойку'
          this.documentAttributes = [
            { label: 'Номер документа', key: 'docNumber', type: 'input', disabled: true },
            { label: 'Компания отправитель', key: 'senderCompany', type: 'select', data: companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Компания получатель', key: 'receiverCompany', type: 'select', data: this.companies, selectKey: 'id', selectLabel: 'name', selectValue: 'id', disabled: true },
            { label: 'Исполнитель', key: 'executor', type: 'select', data: this.executors, selectKey: 'title', selectLabel: 'title', selectValue: 'title', disabled: false },
            { label: 'Дата создания документа', key: 'createdDate', type: 'date', disabled: false }
          ]
          this.workplaceAttributes = [
            { label: 'Вид обработки', key: 'processingType3', type: 'select', data: this.processingTypesExternal, selectKey: 'title', selectLabel: 'title', selectValue: 'title' }
          ]
          this.docForm = {
            docNumber: '',
            executor: '',
            senderCompany: 1,
            receiverCompany: 7,
            createdDate: new Date()
          }
          if (this.isUserBatys) {
            this.docForm.senderCompany = 3
          }
          this.wagonsValues = {
            castingStationRaisingApplication: '',
            indexRaisingApplication: '',
            timeIndustrialRaisingApplication: ''
          }
          this.attributeRequestsName = 'attributeRequestsExternalCleaning'
          this.attributeRequestName = 'attributeRequestExternalCleaning'
          break
      }
      if (this.isUserErtys) {
        this.docForm.senderCompany = 3
        this.docForm.receiverCompany = 8
      } else if (this.isUserKazykurt) {
        this.docForm.senderCompany = 3
        this.docForm.receiverCompany = 7
      } else if (this.isUserBatys) {
        this.docForm.senderCompany = 3
        this.docForm.receiverCompany = 6
      }
    },
    getStatuses () {
      const data = {
        hash: this.documentInfo.idHash
      }
      blockchainApi.getStatus(data).then((resp) => {
        this.statuses = resp.map(item => {
          if (item.status === 'CREATED') {
            item.statusName = 'Создан'
          } else if (item.status === 'SENDED') {
            item.statusName = 'Отправлен'
          } else if (item.status === 'APPROVED') {
            item.statusName = 'Утвержден'
          } else if (item.status === 'RECEIVED') {
            item.statusName = 'Доставлен'
          } else if (item.status === 'REJECTED') {
            item.statusName = 'Отклонен'
          } else if (item.status === 'CANCELED') {
            item.statusName = 'Отменен'
          } else if (item.status === 'UPDATED') {
            item.statusName = 'Отредактирован'
          } else if (item.status === 'CONFIRMED') {
            item.statusName = 'Подтвержден'
          }
          return item
        })
      }).catch(err => {
        console.log('err', err)
      })
    },
    editDocument () {
      this.$router.push({ path: `/company/create-ticket/${this.ticketName}`, query: { hash: this.getHash, type: 'edit' } })
    },
    copyDocument () {
      this.$router.push({ path: `/company/create-ticket/${this.ticketName}`, query: { hash: this.getHash, type: 'copy' } })
    },
    getDocumentByHash () {
      this.isLoading = true
      blockchainApi.getDocumentByHash(this.getHash).then((resp) => {
        try {
          this.documentInfo = resp.data
          this.documentInfo.data = JSON.parse(this.documentInfo.data)
          this.currentMeta = this.documentInfo.data.meta
          this.documentId = this.documentInfo.data.meta.documentId
          this.docType = this.documentInfo.data.meta.docType
          this.docForm.docNumber = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.docNumber
          this.docForm.receiverCompany = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.receiverCompany
          this.docForm.senderCompany = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.senderCompany
          this.docForm.createdDate = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar.createdDate
          this.documentAttributes.forEach(attr => {
            if (attr.key !== 'docNumber' && attr.key !== 'receiverCompany' && attr.key !== 'senderCompany' && attr.key !== 'createdDate') {
              this.docForm[attr.key] = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar[this.attributeRequestsName][attr.key]
            }
          })
          if (this.isCopying) {
            this.docForm.docNumber = ''
            this.docForm.createdDate = new Date()
            this.docForm.executor = null
            this.docForm.receiverCompany = null
            this.docForm.senderCompany = null
            this.docForm.receiverCompany2 = null
          }
          if (this.documentInfo.data.body.documentWorkplaceRailcar.workplaceDocumnet.length > 0) {
            this.tableData = []
            this.documentInfo.data.body.documentWorkplaceRailcar.workplaceDocumnet.forEach(item => {
              const workplace = {
                position: item.position,
                nomerVagona: item[this.attributeRequestName].nomerVagona
              }
              this.workplaceAttributes.forEach(attr => {
                workplace[attr.key] = item[this.attributeRequestName][attr.key]
              })
              this.tableData.push(workplace)
            })
            this.tableData.sort((a, b) => a.position - b.position)
          }
        } catch (e) {
          console.log(e)
        }
        this.getSign()
        this.checkSigning()
        this.getStatuses()
      }).finally(() => {
        this.isLoading = false
      })
    },
    createDocument () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid && this.tableData.length > 0) {
          this.isLoading = true
          const workplaces = []
          this.tableData.forEach(item => {
            const workplace = {
              createdAt: new Date(),
              position: item.position
            }
            workplace[this.attributeRequestName] = {
              nomerVagona: item.nomerVagona
            }
            this.workplaceAttributes.forEach(attr => {
              workplace[this.attributeRequestName][attr.key] = item[attr.key]
            })
            workplaces.push(workplace)
          })
          let receiverBin = []
          let statuses = []
          if (this.isEditing) {
            statuses = ['UPDATED']
            receiverBin = this.currentMeta.receiverBin
          } else {
            statuses = ['CREATED', 'SENDED']
            if (this.docForm.receiverCompany) {
              receiverBin.push(this.getCompanyById(this.docForm.receiverCompany).bin)
            }
            if (this.docForm.receiverCompany2) {
              receiverBin.push(this.getCompanyById(this.docForm.receiverCompany2).bin)
            }
          }
          const documentForm = {}
          this.documentAttributes.forEach(attr => {
            if (attr.key !== 'docNumber' && attr.key !== 'receiverCompany' && attr.key !== 'senderCompany' && attr.key !== 'createdDate') {
              if (attr.key === 'receiverCompany2' && this.docForm[attr.key]) {
                documentForm[attr.key] = this.getCompanyById(this.docForm[attr.key]).name
              } else {
                documentForm[attr.key] = this.docForm[attr.key]
              }
            }
          })
          const data = {
            body: {
              documentWorkplaceRailcar: {
                documentRailcar: {
                  countWorkplaces: this.tableData.length,
                  createAt: new Date(),
                  createdBy: this.getUsername,
                  createdDate: this.docForm.createdDate,
                  docNumber: this.docForm.docNumber,
                  docType: this.ticketName,
                  form: this.ticketName,
                  internalCode: this.docForm.docNumber,
                  receiverCompany: this.isEditing ? this.docForm.receiverCompany : this.getCompanyById(this.docForm.receiverCompany).name,
                  senderCompany: this.isEditing ? this.docForm.senderCompany : this.getCompanyById(this.docForm.senderCompany).name,
                  updatedAt: new Date(),
                  updatedBy: this.getUsername
                },
                workplaceDocumnet: workplaces
              }
            },
            meta: {
              docType: this.ticketName,
              documentId: this.isEditing ? this.currentMeta.documentId : new Date().getTime(),
              isPrivate: false,
              receiverBin: receiverBin,
              sendedAt: new Date(),
              senderBin: this.isEditing ? this.currentMeta.senderBin : this.getUserCompanyBin,
              sendedBy: this.isEditing ? this.currentMeta.sendedBy : this.getUsername,
              statuses
            }
          }
          data.body.documentWorkplaceRailcar.documentRailcar[this.attributeRequestsName] = documentForm
          blockchainApi.createDocument(data).then((resp) => {
            this.$notify.success('Документ успешно создан!')
            this.$router.push('/company/documents/' + this.ticketName)
          }).catch(err => {
            this.$notify.error('Не удалось создать документ', err)
          }).finally(() => {
            this.isLoading = false
          })
        } else {
          this.$notify.warning('Заполните все поля')
        }
      })
    },
    changeRailcarsValue () {
      this.selectRailcars.forEach(item => {
        for (const key of Object.keys(this.wagonsValues)) {
          item[key] = this.wagonsValues[key]
        }
        this.tableData = this.tableData.map(railcar => {
          if (railcar.nomerVagona === item.nomerVagona) {
            railcar = Object.assign(item, {})
          }
          return railcar
        })
      })
      this.cancelChangeRailcars()
    },
    cancelChangeRailcars () {
      this.wagonsValues = {
        eventPodacha: '',
        outGoodPodach: '',
        inGoodPodacha: '',
        commentForRailcarProc: ''
      }
      this.changeWagonsValueDialog = false
    },
    addNewRailcar () {
      if (this.carNumberSearch && this.carNumberSearch.length === 8) {
        railcarApi.addNewRailcar(this.carNumberSearch).then((item) => {
          const newItem = {
            nomerVagona: item.carNumber,
            position: this.tableData.length + 1
          }
          this.workplaceAttributes.forEach(attr => {
            newItem[attr.key] = ''
          })
          this.tableData.push(newItem)
        }).catch((err) => {
          this.$notify.error('Не удалось добавить вагон', err)
        })
      }
    },
    findFromString (str) {
      const arrNum = str.replace(/[\r\n]+/g, ' ').split(' ').map(x => x.trim())
      this.railcars = arrNum.map((railcar, i) => {
        if (this.tableData.filter(item => item.nomerVagona === railcar).length > 0) {
          return {
            position: i,
            carNumber: railcar,
            error: 'Вагон уже добавлен'
          }
        }
        if (railcar.length === 8 && /^[0-9]+$/.test(railcar)) {
          return {
            position: i,
            carNumber: railcar,
            error: false
          }
        } else {
          return {
            position: i,
            carNumber: railcar,
            error: 'Некорректный номер'
          }
        }
      })
    },
    addRailcarsFromList () {
      const arrNum = this.railcars.filter((railcar) => railcar.error === false).map(railcar => railcar.carNumber)
      const railcarsLength = this.railcars.length
      const promises = []
      arrNum.forEach((railcar) => {
        if (railcar.length === 8) {
          promises.push(railcarApi.checkRailcar(railcar).then(resp => {
            return { railcar: railcar, res: resp }
          }))
        }
      })
      Promise.all(promises).then(async (resp) => {
        let counter = 0
        for (const item of resp) {
          if (item.res) {
            if (!this.tableData.find(railcar => railcar.nomerVagona === item.railcar)) {
              const newItem = {
                nomerVagona: item.railcar,
                position: this.tableData.length + 1
              }
              this.workplaceAttributes.forEach(attr => {
                newItem[attr.key] = ''
              })
              this.tableData.push(newItem)
              counter++
            }
          } else {
            await railcarApi.addNewRailcar(item.railcar).then(() => {
              if (!this.tableData.find(railcar => railcar.nomerVagona === item.railcar)) {
                const newItem = {
                  nomerVagona: item.railcar,
                  position: this.tableData.length + 1
                }
                this.workplaceAttributes.forEach(attr => {
                  newItem[attr.key] = ''
                })
                this.tableData.push(newItem)
                counter++
              }
            }).catch((err) => {
              console.log('err', err)
            })
          }
        }
        this.cancelAddRailcars()
        this.$notify.success(`Добавлено вагонов ${counter} из ${railcarsLength}`)
      })
    },
    cancelAddRailcars () {
      this.railcarsList = ''
      this.railcars = []
      this.addRailcarsDialog = false
    },
    getSign () {
      fetch(MAIN_API + '/api/podpis/get/cms/file?id=' + this.documentId)
        .then(resp => {
          return resp.blob()
        }).then(blob => {
          this.submitUpload(blob)
        })
    },
    submitUpload (file) {
      const data = new FormData()
      data.append('file', file, 'file')
      signApi.checkSign(data).then((resp) => {
        try {
          this.signData = JSON.parse(resp.body).map(item => {
            const dateFormat = 'dd.MM.yyyy HH:mm:ss'
            item.time_from = item.time_from.split(' ').slice(0, -1).join(' ')
            item.until = item.until.split(' ').slice(0, -1).join(' ')
            item.time_from_date = parse(item.time_from, dateFormat, new Date())
            item.until_date = parse(item.until, dateFormat, new Date())
            item.result = item.until_date > new Date() ? 'Успешно' : 'Срок действия сертификата истек'
            return item
          })
        } catch (e) {
          console.log(e)
        }
      }).catch(err => {
        console.log(err)
      })
      // this.$refs.upload.submit()
    },
    saveUpdatedBase64 () {
      const meta = this.currentBase64Item.meta
      meta.receiverBin = [meta.senderBin]
      meta.senderBin = this.getUserCompanyBin
      meta.docType = meta.doctype
      meta.sendedAt = new Date()
      meta.statuses = ['APPROVED']
      const data = {
        meta,
        body: { base64: this.currentBase64 }
      }
      blockchainApi.createDocument(data).then(resp => {
        console.log(resp)
      }).catch(err => {
        console.log(err)
      })
    },
    getFilteredHistory () {
      this.isLoading = true
      blockchainApi.filterDocument(this.getHash)
        .then(resp => {
          if (resp && resp.length > 0) {
            this.documentHistory = resp.map(item => {
              if (this.isJson(item.data)) {
                // item.data = JSON.stringify(JSON.parse(item.data), null, 2)
                item.data = JSON.parse(item.data)
              } else {
                if (item.meta.extension.includes('cms')) {
                  this.currentCms = item.data
                } else if (item.meta.extension.includes('pdf')) {
                  this.currentBase64Pdf = item.data
                  this.currentBase64Item = item
                  this.currentBase64 = item.data
                }
              }
              return item
            })
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    checkSigning () {
      signApi.checkSignAccessById(this.documentId).then(resp => {
        if (resp.documentId) {
          this.isDocumentSigned = true
        }
      })
    },
    downloadSign () {
      fetch(MAIN_API + '/api/podpis/get/cms/file?id=' + this.documentId)
        .then(resp => {
          return resp.blob()
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = this.docType + '_' + this.documentId + '.pdf.cms'
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(error => console.error('Error:', error))
    },
    signDocument () {
      console.log('asdasd')
      if (this.isMobile) {
        this.signDialogVisible = true
      } else {
        this.sign()
      }
    },
    getUsersKey () {
      const userId = JSON.parse(authorizationAPI.getUserInfo()).id
      signApi.getUserKeysById(userId).then(resp => {
        this.usersKey = resp
      })
    },
    signNcaNode () {
      this.isLoading = true
      this.signDialogVisible = false
      if (this.usersKey.length === 0) {
        this.$notify.info('Нет загруженных ключей')
        this.isLoading = false
        return
      }
      const data = {
        signers: [
          {
            key: this.usersKey[0].key,
            password: this.ecpPassword
          }
        ],
        withTsp: true,
        tsaPolicy: 'TSA_GOST_POLICY',
        detached: false,
        data: this.currentBase64
      }
      signApi.signNcaNode(data).then(resp => {
        const signatureResult = resp.cms
        const data = {
          documentId: this.documentId,
          base64: signatureResult,
          docType: this.docType
        }
        signApi.saveSignDocument(data).then(async resp => {
          alert('Документ успешно подписан!')
          await this.saveUpdatedBase64()
          await this.addDocumentSigners()
          this.getDocumentByHash()
        }).catch(err => {
          alert('Не удалось подписать документ ', err)
        })
        console.log('resp', resp)
      }).catch(err => {
        console.log('err', err)
      })
      console.log('signNcaNode')
    },
    sign () {
      const ncalayerClient = new NCALayerClient()
      console.log(this.currentBase64)
      ncalayerClient.connect().then(async () => {
        const signatureResult = await ncalayerClient.createCMSSignatureFromBase64('PKCS12', this.currentBase64, 'SIGNATURE', true)
        console.log(signatureResult)
        const data = {
          documentId: this.documentId,
          base64: signatureResult,
          docType: this.docType
        }
        signApi.saveSignDocument(data).then(resp => {
          alert('Документ успешно подписан!')
          this.saveUpdatedBase64()
          this.getDocumentByHash()
        }).catch(err => {
          alert('Не удалось подписать документ ', err)
        })
      }).catch(err => {
        console.log(err)
        alert('Запустите ncaLayer', err)
      })
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',')[1])
        reader.onerror = (error) => reject(error)
      })
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex === 0) {
        return 'success-row'
      }
      return ''
    },
    rowClick (val) {
      this.currentHistoryItem = val
      this.historyDialog = true
    },
    carSelect (item) {
      if (!this.tableData.find(railcar => railcar.nomerVagona === item.carNumber)) {
        const newItem = {
          position: this.tableData.length + 1,
          nomerVagona: item.carNumber,
          carId: item.id
        }
        this.workplaceAttributes.forEach(attr => {
          newItem[attr.key] = ''
        })
        this.tableData.push(newItem)
      } else {
        this.$notify.warning('Вагон уже добавлен')
      }
      this.carNumberSearch = ''
    },
    querySearch (str, cb) {
      if (str.length > 2) {
        railcarApi.findRailcars(str).then(resp => {
          cb(resp)
          this.canAddNewRailcar = str.length === 8 && resp.length === 0
        })
      }
    },
    rejectTicket (rejectValue) {
      if (rejectValue) {
        const workplaces = []
        this.tableData.forEach(item => {
          const workplace = {
            createdAt: new Date(),
            position: item.position
          }
          workplace[this.attributeRequestName] = {
            nomerVagona: item.nomerVagona
          }
          this.workplaceAttributes.forEach(attr => {
            workplace[this.attributeRequestName][attr.key] = item[attr.key]
          })
          workplaces.push(workplace)
        })
        const documentRailcar = this.documentInfo.data.body.documentWorkplaceRailcar.documentRailcar
        documentRailcar.rejectingReason = rejectValue
        const meta = this.documentInfo.meta
        meta.docType = meta.doctype
        meta.sendedAt = new Date()
        meta.statuses = ['CANCELED']
        const data = {
          body: {
            documentWorkplaceRailcar: {
              documentRailcar,
              workplaceDocumnet: workplaces
            }
          },
          meta
        }
        blockchainApi.createDocument(data).then((resp) => {
          this.$notify.success('Заявка успешно отклонена!')
          this.$router.push('/company/documents/' + this.ticketName)
        }).catch(err => {
          this.$notify.error('Не удалось отклонить заявку', err)
        }).finally(() => {
          this.rejectDialog = false
          this.rejectValue = ''
          this.isLoading = false
        })
      } else {
        this.$notify.warning('Заполните поле')
      }
    },
    getCompanies () {
      companiesApi.getCompanies().then(resp => {
        this.companies = resp
        if (this.isNewDoc) {
          this.docFormChanges()
        }
      })
    },
    getCompanyById (id) {
      return this.companies.filter(item => item.id === id)[0]
    },
    stepHandle (val) {
      if (val === 'next' && this.activeName !== this.tabsAmount.toString()) {
        this.activeName = (parseInt(this.activeName) + 1).toString()
      }
      if (val === 'back' && this.activeName !== '1') {
        this.activeName = (parseInt(this.activeName) - 1).toString()
      }
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    goBack () {
      this.$router.go(-1)
    },
    deleteRow () {
      this.selectRailcars.forEach(railcar => {
        this.tableData = this.tableData.filter(item => item.nomerVagona !== railcar.nomerVagona)
      })
    },
    getFormattedDate (date) {
      return dateFormat.getFormattedDate(date)
    },
    handleSelectionChange (event) {
      if (this.isShiftPressed && event.length === 2) {
        return
      }
      this.selectRailcars = event
    }
  }
}
</script>

<style>
.reject {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fc8181;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
.el-form-item {
  margin-right: 10px;
}
.railcarTable {
  border-collapse: collapse;
}
.railcarTable td {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

</style>
