<template>
  <div>
    <h2 class="page-title">Подпись</h2>
    <el-card v-loading="isLoading">
      <div v-if="userKeys.length === 0">У вас нет загруженных файлов</div>
      <br>
      <el-upload
          class="upload-demo"
          ref="upload"
          :action="url"
          :file-list="fileList"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :limit="3"
          :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">Выбрать файл</el-button>
        <el-button style="margin-left: 10px;" size="small" :disabled="fileList.length === 0" @click="submitUpload">Загрузить</el-button>
      </el-upload>
    </el-card>
    <el-card v-if="userKeys.length > 0" style="margin-top: 30px;">
      <h3>Информация о ключе</h3>
      <table v-for="item in userKeys" :key="item.id" style="margin-bottom: 30px;">
        <tr>
          <td>Результат</td>
          <td></td>
        </tr>
        <tr>
          <td>Ключ обнаружен</td>
          <td><el-button @click="deleteUserKey()">Удалить</el-button></td>
        </tr>
      </table>
    </el-card>
  </div>
</template>

<script>
import signApi from '@/modules/api/sign'
import authorizationAPI from '@/modules/api/authorization'

export default {
  name: 'SignUpload',
  data () {
    return {
      isLoading: false,
      fileList: [],
      userKeys: [],
      userId: '',
      data: [
        // {
        //   issuer_name: 'ҰЛТТЫҚ КУӘЛАНДЫРУШЫ ОРТАЛЫҚ (RSA)',
        //   issuer_country: 'KZ',
        //   country: 'KZ',
        //   name: 'МАМЫРБАЕВ АЙДЫН',
        //   surname: 'МАМЫРБАЕВ',
        //   father_name: 'МУРАТОВИЧ',
        //   iin: '971006350032',
        //   email: 'CAPSLOCK722@GMAIL.COM',
        //   certificate: '61F7C5F7439E633F57104FFB4963AFEF89478B5C',
        //   organization: '',
        //   organization_bin: '',
        //   time_from: '19.09.2022 13:14:50 ALMT',
        //   until: '19.09.2023 13:14:50 ALMT'
        // }
      ]
    }
  },
  mounted () {
    this.userId = JSON.parse(authorizationAPI.getUserInfo()).id
    this.getData()
  },
  methods: {
    getData () {
      this.isLoading = true
      signApi.getUserKeysById(this.userId).then(resp => {
        this.userKeys = resp
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.isLoading = false
      })
    },
    submitUpload (event) {
      this.convertToBase64(this.fileList[0].raw)
      // this.$refs.upload.submit()
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
      this.fileList = []
    },
    handleChange (file, fileList) {
      console.log(file, fileList)
      this.fileList = [file]
    },
    deleteUserKey () {
      this.isLoading = true
      const isDelete = confirm('Вы хотите удалить ключ?')
      if (isDelete) {
        signApi.deleteUserKeys(this.userKeys[0].id).then(() => {
          this.$notify.success('Ключ успешно удален!')
          this.isLoading = false
          this.getData()
        }).catch(() => {
          this.isLoading = false
          this.$notify.error('Не удалось удалить ключ!')
        })
      } else {
        this.isLoading = false
      }
    },
    convertToBase64 (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.isLoading = true
        const base64 = reader.result.split(',')[1]
        if (this.userKeys.length > 0) {
          const data = {
            key: base64
          }
          signApi.refreshUserKeys(this.userKeys[0].id, data).then(() => {
            this.$notify.success('Ключ успешно обновлен!')
            this.isLoading = false
            this.getData()
          }).catch(() => {
            this.$notify.error('Не удалось обновить ключ!')
          })
        } else {
          const data = {
            userId: this.userId,
            key: base64
          }
          signApi.createUserKeys(data).then(() => {
            this.$notify.success('Ключ успешно сохранен!')
            this.isLoading = false
            this.getData()
          }).catch(() => {
            this.$notify.error('Не удалось сохранить ключ!')
          })
        }
        console.log('Base64:', base64)
      }
      reader.onerror = (error) => {
        this.$notify.success('Не удалось сохранить ключ' + error)
      }
    }
  }
}
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f3f3f3;
}
</style>
