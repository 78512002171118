import axios from 'axios'
import { MAIN_API } from './apiUrl'

const signApi = {
  // checkSign (data) {
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       url: SIGN_API + '/check-sign.php',
  //       data,
  //       method: 'POST'
  //       // auth: {
  //       //   username: 'altersign',
  //       //   password: '3tlzEyBC9oiAy0QCwA8x6eSqv1PhuXB'
  //       // }
  //     }).then(response => {
  //       resolve(response.data)
  //     }).catch((error) => {
  //       reject(error)
  //     })
  //   })
  // },
  checkSign (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/blockchain/check-sign',
        data,
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  checkSignAccessById (id) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/podpis/find?id=' + id,
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  saveSignDocument (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/podpis/save/base64/file',
        data,
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  saveSignAgreement (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/api/agreements/sign',
        data,
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  signNcaNode (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/proxy/sign',
        data,
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  addSignNcaNode (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/proxy/sign/add',
        data,
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getUserKeysById (id) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/user-keys/' + id,
        method: 'GET'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  createUserKeys (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/user-keys',
        data,
        method: 'POST'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  refreshUserKeys (id, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/user-keys/' + id,
        data,
        method: 'PUT'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deleteUserKeys (id) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/user-keys/' + id,
        method: 'DELETE'
      }).then(response => {
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default signApi
