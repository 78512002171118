<template>
  <el-container id="app">
    <transition name="component-fade">
      <the-navigation v-if="$route.path !== '/login-page' && $route.path !== '/registration'" :showMenu="showMenu"></the-navigation>
    </transition>
    <el-container>
      <el-main class="content">
        <div v-if="isMobile && $route.path !== '/login-page' && $route.path !== '/registration'" class="mobile-nav">
          <div class="logo-nav">
            <img src="./assets/logo.svg" alt="Alter Portal Logo">
          </div>
          <button class="burger-menu" @click="toggleMenu" :class="{ open: showMenu }">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
          </button>
        </div>
        <transition name="component-fade" mode="out-in">
          <router-view/>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import TheNavigation from './components/layout/TheNavigation'

export default {
  components: {
    TheNavigation
  },
  data () {
    return {
      isMobile: false,
      showMenu: false
    }
  },
  mounted () {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkScreenSize)
  },
  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu
    },
    checkScreenSize () {
      this.isMobile = window.innerWidth <= 768
      if (!this.isMobile) {
        this.isMenuVisible = true // Показывать меню на больших экранах
      } else {
        this.isMenuVisible = false // Прятать меню на мобильных
      }
    }
  }
}
</script>

<style lang="scss">
  html, body, textarea {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: "Gotham", sans-serif !important;
    background: linear-gradient(90deg, #e6e9fa 0%, #f1f3f8 50%, #d6dcf5 80%);
  }

  #app {
    height: 100%;
  }

  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .2s ease;
  }
  .component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  .burger-menu {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    z-index: 1000;
    position: relative;
  }
  .bar {
    width: 30px;
    height: 4px;
    background-color: #3566bd;
    border-radius: 2px;
    transition: 0.3s;
  }

  /* Анимация превращения в крестик */
  .burger-menu.open .bar:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }
  .burger-menu.open .bar:nth-child(2) {
    opacity: 0;
  }
  .burger-menu.open .bar:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
