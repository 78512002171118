<template>
  <el-aside v-if="showMenu || !isMobile" class="aside">
      <el-menu router class="el-menu-vertical-demo" :collapse="isCollapse" unique-opened>
        <div v-if="!isMobile" class="logo-nav">
          <img src="../../assets/logo.svg" alt="Alter Portal Logo">
          <div>
            {{ getFio }}
          </div>
        </div>
        <el-menu-item v-for="item in menu" :key="item.index" :index="item.index">
          <i :class="item.icon"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
        <el-submenu index="agreement" v-if="canWatchAgreement && !isRolePetroleum">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Договора</span>
          </template>
          <el-menu-item index="/company/agreement/agreement/true">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">Подписанные</span>
          </el-menu-item>
          <el-menu-item index="/company/agreement/agreement/false">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">Не подписанные</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="agreement2" v-if="canWatchAgreement && isRolePetroleum">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Договора</span>
          </template>
          <el-menu-item index="/company/agreementpet/agreement">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">Подписанные</span>
          </el-menu-item>
          <el-menu-item index="/company/agreement/agreement/false">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">Не подписанные</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu v-if="isPtc && canWatchDocuments" index="vagonnik">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Вагонники</span>
          </template>
          <el-submenu index="vagonnik1">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Полученные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="`/company/documents/${getDocumentsList('VAGONNIK').map(item => item.key).toString()}?bin=19114002534511`">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-for="item in getDocumentsList('VAGONNIK')" :key="item.key">
              <el-menu-item :index="`/company/documents/${item.key}?bin=19114002534511`">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-submenu>

        <el-submenu v-if="isPtc && canWatchDocuments" index="ertys">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>ТОО “Ертыс-сервис”</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/register?type=reestrVC">Реестр с расчетами</el-menu-item>
          </el-menu-item-group>
          <el-submenu index="ertys1">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Полученные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="`/company/documents/${getDocumentsList('ERTYS').map(item => item.key).toString()}?bin=1911400253455`">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-for="item in getDocumentsList('ERTYS')" :key="item.key">
              <el-menu-item :index="`/company/documents/${item.key}?bin=1911400253455`">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="ertys2">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Отправленные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="`/company/documents/${getTicketsList('ERTYS').map(item => item.key).toString()}?bin=1911400253455`">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-for="item in getTicketsList('ERTYS')" :key="item.key">
              <el-menu-item :index="`/company/documents/${item.key}?bin=1911400253455`">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-submenu>
        <el-submenu v-if="isPtc && canWatchDocuments" index="ertysArchive">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>ТОО “Ертыс-сервис” (Архив)</span>
          </template>
          <el-submenu index="ertys4">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Полученные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/vu20,vu19,vu20a?bin=070240009538">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/vu20?bin=070240009538">Ву-20</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/vu20a?bin=070240009538">Ву-20а</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/vu19?bin=070240009538">Ву-19</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/act?bin=070240009538">Акт</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="ertys5">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Отправленные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/processing,vrd?bin=070240009538">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/processing?bin=070240009538">Заявка на обработку</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/company/documents/vrd?bin=070240009538">Заявка на ВРД</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-submenu>
        <el-submenu v-if="isPtc && canWatchDocuments" index="kazykurt">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>ТОО “Казыкурт-ЮГ”</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/register?type=reestrShymkent">Реестр с расчетами</el-menu-item>
          </el-menu-item-group>
          <el-submenu index="kaz1">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Полученные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="`/company/documents/${getDocumentsList('KAZYKURT').map(item => item.key).toString()}?bin=1911400253454`">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-for="item in getDocumentsList('KAZYKURT')" :key="item.key">
              <el-menu-item :index="`/company/documents/${item.key}?bin=1911400253454`">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="kaz2">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Отправленные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="`/company/documents/${getTicketsList('KAZYKURT').map(item => item.key).toString()}?bin=1911400253453`">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-for="item in getTicketsList('KAZYKURT')" :key="item.key">
              <el-menu-item :index="`/company/documents/${item.key}?bin=1911400253453`">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-submenu>
        <el-submenu v-if="isPtc && canWatchDocuments" index="batys">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>ТОО “Batys Petroleum”</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/register?type=reestrBatys">Реестр с расчетами</el-menu-item>
          </el-menu-item-group>
          <el-submenu index="kaz1">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Полученные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="`/company/documents/${getDocumentsList('BATYS').map(item => item.key).toString()}?bin=1911400253453`">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-for="item in getDocumentsList('BATYS')" :key="item.key">
              <el-menu-item :index="`/company/documents/${item.key}?bin=1911400253453`">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="kaz2">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Отправленные</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="`/company/documents/${getTicketsList('BATYS').map(item => item.key).toString()}?bin=1911400253453`">Все документы</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-for="item in getTicketsList('BATYS')" :key="item.key">
              <el-menu-item :index="`/company/documents/${item.key}?bin=1911400253453`">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-submenu>
        <el-submenu v-if="isCompanyUserOrAdmin && canWatchDocuments && !isPtc" index="documents">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Полученные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :index="'/company/documents/' + documentsList.map(item => item.key).toString()">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-for="item in documentsList" :key="item.key">
            <el-menu-item :index="'/company/documents/' + item.key">{{ item.title }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu v-if="isCompanyUserOrAdmin && canWatchTickets && !isPtc" index="ticketsBatys">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Отправленные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :index="'/company/documents/' + ticketList.map(item => item.key).toString()">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-for="item in ticketList" :key="item.key">
            <el-menu-item :index="'/company/documents/' + item.key">{{ item.title }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/login-page">
          <span slot="title">Выйти</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
</template>

<script>
import authorizationAPI from '@/modules/api/authorization'
import {
  canWatchTickets,
  canWatchDocuments,
  canWatchAgreement,
  getDocumentsList, getTicketsList
} from '@/modules/permissions/permissions'
export default {
  props: {
    showMenu: Boolean
  },
  data () {
    return {
      isMenuVisible: false,
      isMobile: false,
      menu: [],
      documentsList: [],
      ticketList: []
    }
  },
  mounted () {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
    this.initNavigationByRole()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkScreenSize)
  },
  computed: {
    isCollapse () {
      return this.isMobile // Если мобильное устройство, меню будет свернуто
    },
    getFio () {
      return authorizationAPI.getFio()
    },
    isPtc () {
      return authorizationAPI.isRolePtc()
    },
    isUserBatys () {
      return authorizationAPI.isRoleBatys()
    },
    isUserErtys () {
      return authorizationAPI.isRoleErtys()
    },
    isUserKazykurt () {
      return authorizationAPI.isRoleKazykurt()
    },
    isRolePetroleum () {
      return authorizationAPI.isRolePetroleum()
    },
    isCompanyUserOrAdmin () {
      return !authorizationAPI.isRoleSuperAdmin()
    },
    canWatchTickets () {
      return canWatchTickets(authorizationAPI.getRole())
    },
    canWatchDocuments () {
      return canWatchDocuments(authorizationAPI.getRole())
    },
    canWatchAgreement () {
      return canWatchAgreement(authorizationAPI.getRole())
    }
  },
  methods: {
    checkScreenSize () {
      this.isMobile = window.innerWidth <= 768
      if (!this.isMobile) {
        this.isMenuVisible = true // Показывать меню на больших экранах
      } else {
        this.isMenuVisible = false // Прятать меню на мобильных
      }
    },
    initNavigationByRole () {
      if (authorizationAPI.isRoleSuperAdmin()) {
        this.menu = [
          {
            index: '/super/documents',
            icon: 'el-icon-document',
            title: 'Документы'
          },
          {
            index: '/super/services',
            icon: 'el-icon-s-unfold',
            title: 'Сервисы'
          },
          {
            index: '/super/users',
            icon: 'el-icon-user-solid',
            title: 'Пользователи'
          }
        ]
      } else if (authorizationAPI.isRoleCompanyAdmin()) {
        this.menu = [
          {
            index: '/company/checkSign',
            icon: 'el-icon-edit-outline',
            title: 'Проверка подписи'
          },
          {
            index: '/company/users',
            icon: 'el-icon-user-solid',
            title: 'Пользователи'
          }
        ]
      } else if (authorizationAPI.isRoleUser()) {
        this.menu = [
          {
            index: '/company/checkSign',
            icon: 'el-icon-edit-outline',
            title: 'Проверка подписи'
          },
          {
            index: '/company/sign-upload',
            icon: 'el-icon-edit-outline',
            title: 'Файл для подписи'
          }
        ]
      }
      if (this.isUserBatys) {
        this.documentsList = getDocumentsList('BATYS')
        this.ticketList = getTicketsList('BATYS')
      }
      if (this.isUserErtys) {
        this.documentsList = getDocumentsList('ERTYS')
        this.ticketList = getTicketsList('ERTYS')
      }
      if (this.isUserKazykurt) {
        this.documentsList = getDocumentsList('KAZYKURT')
        this.ticketList = getTicketsList('KAZYKURT')
      }
    },
    getDocumentsList (station) {
      return getDocumentsList(station)
    },
    getTicketsList (station) {
      return getTicketsList(station)
    }
  }
}
</script>

<style>
.aside {
  background-color: rgb(238, 241, 246);
  width: auto !important;
  min-width: 60px; /* Например, минимальная ширина для иконок */
  max-width: 250px; /* Максимальная ширина при раскрытии */
  transition: width 0.3s ease-in-out;
  /*max-width: 250px;*/
}

.el-menu-vertical-demo {
  min-height: 100%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  /*width: 200px;*/
  min-height: 100%;
}

.logo-nav {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 20px;
  margin-top: 20px !important;
  padding-left: 10px;
}

.logo-nav img {
  height: 60px;
}
.el-submenu .el-menu-item {
  font-size: 14px;
  padding: 0;
}
.el-menu-item-group__title {
  padding: 0 !important;
}

</style>
